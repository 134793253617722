export const fetchDadJokes = async (limit: number = 1): Promise<any[]> => {
    const apiKey: string = 'W11mImqCBez2mBvq83kytQ==Dbj3ejJmCNvoxVAY';
    const url: string = `https://api.api-ninjas.com/v1/dadjokes`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        // Check if the response is OK (status code 200)
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        // Parse the response data as JSON and return
        const jokes = await response.json();
        return jokes;

    } catch (error) {
        console.error('Fetch error:', error);
        throw error;  // Re-throw the error so it can be handled in the component
    }
};

export const easyComputer10 = async (): Promise<any[]> => {
    const url: string = `https://opentdb.com/api.php?amount=10&category=18&difficulty=easy&type=multiple`;  // Use the correct API URL

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'  // Keep this to specify the content type
            }
        });

        // Check if the response is OK (status code 200)
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        // Parse the response data as JSON and return
        const questions = await response.json();
        return questions;

    } catch (error) {
        console.error('Fetch error:', error);
        throw error;  // Re-throw the error so it can be handled in the component
    }
};