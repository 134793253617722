import React, { useEffect, useRef, useState } from 'react';
import style from '../styles/projects.module.css';
import fader from '../styles/fader.module.css';
import section from '../styles/section.module.css';
import projectsImage from '../assets/projects.png';
import { Tilt } from "react-tilt";

const Projects: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);

    const projects = [
        { title: "Terminal Messaging Program", description: "A terminal-based client-server messaging program with a focus on Authentication and Encryption using RSA and AES and salted passwords with SHA-256.", link: "https://github.com/AndyMac124/TerminalMessaging" },
        { title: "SpaceMaze Game", description: "A few of the Java classes I was the sole author of in our class-wide mini-game server project.", link: "https://github.com/AndyMac124/SpaceMazeClasses" },
        { title: "Reversi Game", description: "A game of Reversi using a little AI utility agent, I authored the Reversi.scala and ReversiSuite.scala.", link: "https://github.com/AndyMac124/Reversi/tree/main" },
        { title: "Character Counting", description: "A C program which takes in a directory with plain text files to then perform a frequency analysis on the characters.", link: "https://github.com/AndyMac124/characterCounting/tree/main" },
    ];

    const defaultOptions = {
        reverse:        false,  // reverse the tilt direction
        max:            90,     // max tilt rotation (degrees)
        perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
        scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
        speed:          800,   // Speed of the enter/exit transition
        transition:     true,   // Set a transition on enter/exit.
        axis:           null,   // What axis should be disabled. Can be X or Y.
        reset:          true,    // If the tilt effect has to be reset on exit.
        easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className={section.container}>
            <section
                id="projects"
                className={`${fader.container} ${isVisible ? fader.fadeIn : fader.fadeOut}`}
                ref={sectionRef}
            >
                <img
                    src={projectsImage}
                    alt="Projects Icon"
                    className={style.projectImage}
                />
                <h2>Projects</h2>
                <p>The cards below are linked to the GitHub Repos for the given projects, feel free to click on some and
                    have a look.</p>
                <p>I have a lot more projects I can't make public right now, some notable ones include the following:</p>
                <ul>
                    <li>A C program using MPI to perform a convolution filter on a matrix.</li>
                    <li>A CUDA C program to generate a Julia Fractal bitmap image.</li>
                    <li>A game of Saboteur in Python with PyGame and two types of AI agents competing.</li>
                    <li>Implementation of Monte Carlo Tree Search in the game of Connect Four.</li>
                </ul>
                <div className={style.cardsContainer}>
                    {projects.map((project, index) => (
                        <Tilt key={index}>
                            <div className={style.card}>
                                <a href={project.link} target="_blank" rel="noopener noreferrer">
                                    <p><strong>{project.title}</strong></p>
                                    <p>{project.description}</p>
                                </a>
                            </div>
                        </Tilt>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Projects;