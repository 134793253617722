import React from 'react';
import Header from '../components/Header';
import Welcome from '../components/Welcome';
import About from '../components/About';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import Education from '../components/Education';
import Examples from '../components/Examples';
import DadJokes from '../components/DadJokes';
import Trivia from '../components/Trivia';
import '../styles/App.css';

const App: React.FC = () => {
  return (
      <div>
        <Header />
        <main>
            <Welcome />
            <About />
            <Education />
            <Skills />
            <Projects />
            <Examples />
            <DadJokes />
            <Trivia />
            <Contact />
        </main>
        <footer>
          <p>© 2024 Andrew McKenzie</p>
        </footer>
      </div>
  );
};

export default App;