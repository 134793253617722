import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styles from '../styles/contact.module.css';
import section from '../styles/section.module.css';
import emailjs from '@emailjs/browser';
import contactImage from '../assets/contact.png';

type FormState = {
    name: string;
    email: string;
    message: string;
};

export default function Contact() {
    const [formData, setFormData] = useState<FormState>({ name: '', email: '', message: '' });
    const [submitted, setSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false); // Track the sending status

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsSending(true);

        try {
            const result = await emailjs.send('service_tpdlw6g', 'template_j080myq', formData, 'CfqDGVdgrNdzKcyTk');

            if (result.status === 200) {
                setSubmitted(true);
            } else {
                console.error('Error sending message:', result.text);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <main id="contact" className={section.container}>
            <motion.div
                className={styles.contactForm}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                <img
                    src={contactImage}
                    alt="Contact Icon"
                    className={styles.contactImage}
                />
                <h3 className={styles.heading}>Get in Touch</h3>
                {!submitted ? (
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label htmlFor="name" className={styles.label}>Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className={styles.input}
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="email" className={styles.label}>Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className={styles.input}
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="message" className={styles.label}>Message</label>
                            <textarea
                                id="message"
                                name="message"
                                className={styles.textarea}
                                value={formData.message}
                                onChange={handleInputChange}
                                rows={6}
                                required
                            />
                        </div>

                        <motion.button
                            type="submit"
                            className={styles.submitButton}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            disabled={isSending}
                        >
                            {isSending ? 'Sending...' : 'Send'}
                        </motion.button>
                    </form>
                ) : (
                    <motion.p
                        className={styles.successMessage}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        Thank you! Your message has been sent.
                    </motion.p>
                )}
            </motion.div>
        </main>
    );
}