import React, { useEffect, useRef, useState } from 'react';
import style from '../styles/dadjokes.module.css';
import fader from '../styles/fader.module.css';
import section from "../styles/section.module.css";
import { fetchDadJokes } from '../services/api';  // Assuming this is the service to fetch dad jokes

const DadJokes: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [jokes, setJokes] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // IntersectionObserver to handle fadeIn/fadeOut
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setIsVisible(entry.isIntersecting);  // Trigger fadeIn when visible
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    // Function to fetch jokes
    const loadJokes = async () => {
        try {
            setLoading(true);
            setError(null);  // Reset error state before fetching
            const jokesData = await fetchDadJokes(3);  // Fetch 3 jokes
            console.log(jokesData);  // Log the response to inspect the structure
            if (Array.isArray(jokesData)) {
                setJokes(jokesData.map((jokeItem: any) => jokeItem.joke));
            } else {
                throw new Error('Unexpected response format');
            }
            setLoading(false);
        } catch (err: any) {
            setError('Failed to fetch jokes. Please try again later.');
            console.error(err);
            setLoading(false);
        }
    };

    // Fetch Dad Jokes when the component mounts
    useEffect(() => {
        loadJokes();  // Fetch jokes initially
    }, []);

    return (
        <div className={section.container}>
            <section
                id="dadjokes"
                className={`${fader.container} ${isVisible ? fader.fadeIn : fader.fadeOut}`}
                ref={sectionRef}
            >
                <div>
                    <h2 className={style.h2}>A little API fun with Dad Jokes</h2>
                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                        <ul>
                            {jokes.length > 0 ? (
                                jokes.map((joke, index) => (
                                    <p key={index}>{joke}</p>
                                ))
                            ) : (
                                <li>No jokes available</li>
                            )}
                        </ul>
                    )}
                    {/* Refresh Button */}
                    <button onClick={loadJokes} className={style.button}>Refresh</button>
                </div>
            </section>
        </div>
    );
};

export default DadJokes;