import React, {useEffect, useRef, useState} from 'react';
import style from '../styles/education.module.css';
import fader from '../styles/fader.module.css';
import eduImage from '../assets/education.png';
import section from '../styles/section.module.css';

interface Education {
    school: string;
    degree: string;
    year: string;
    description: string;
}

const About: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);

    const educationData: Education[] = [
        {
            school: 'University of New England',
            degree: 'Bachelor of Computer Science',
            year: '2022 - 2025',
            description: 'Majoring in Software Development.',
        },
        {
            school: 'New Zealand Institute of Sport',
            degree: 'Diploma in Exercise Prescription',
            year: '2009',
            description: 'Graduated with academic excellence and sportsman of the year.',
        },
        {
            school: 'New Zealand Institute of Sport',
            degree: 'Certificate in Sport Management',
            year: '2008',
            description: '',
        },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section
            id="education"
            className={`${fader.container} ${isVisible ? fader.fadeIn : fader.fadeOut} ${section.container}`}
            ref={sectionRef}
        >
            <img
                src={eduImage}
                alt="Education Icon"
                className={style.educationImage}
            />
            <div className={style.educationSection}>
                <h3>Education</h3>
                {educationData.map((education, index) => (
                    <div key={index} className={style.educationItem}>
                        <p><strong>{education.degree}</strong></p>
                        <p>{education.school}: {education.year}</p>
                        <p>{education.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default About;