import React from 'react';
import { Tilt } from 'react-tilt';
import { motion } from 'framer-motion';
import { animate, tiltMotion } from '../utils/motion';
import styles from "../styles/images.module.css";
import Git from "../images/git.png";
import Scala from "../images/scala.png";
import Java from "../images/java.png";
import C from "../images/c.png";
import Cpp from "../images/Cpp.png";
import Docker from "../images/docker.png";
import html from "../images/html.png";
import js from "../images/javascript.png";
import tw from "../images/tailwind.png";
import ts from "../images/typescript.png";
import css from "../images/css.png";
import react from "../images/reactjs.png";
import python from "../images/python.png";
import linux from "../images/linux.png";
import unix from "../images/unix.png";

const images = [Git, Java, Scala, C, Cpp, Docker, linux, unix, html, css, js, python, react, tw, ts];

const repeatArray = (arr: string[], times: number) => {
    return Array(times).fill([...arr]).flat();
};

const repeatedImages = repeatArray(images, 4);

export default function SkillsImages() {
    return (
        <main className={styles.main}>
            <div className={styles.cardsContainer}>
                {repeatedImages.map((src, index) => (
                    <Tilt options={tiltMotion} key={index}>
                        <motion.div initial="hidden" animate="visible" variants={animate}>
                            <div className={styles.tiltCard}>
                                <img src={src} alt={`${src} Logo`} width={60} height={60} />
                            </div>
                        </motion.div>
                    </Tilt>
                ))}
            </div>
        </main>
    );
}