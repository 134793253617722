import React, { useState } from 'react';
import headerStyles from '../styles/header.module.css';
import section from '../styles/section.module.css';
import logo from '../assets/logo.png';

const navLinks = [
    { id: 'welcome', title: 'Welcome' },
    { id: 'about', title: 'About' },
    { id: 'education', title: 'Education' },
    { id: 'skills', title: 'Skills' },
    { id: 'projects', title: 'Projects' },
    { id: 'examples', title: 'Examples' },
    { id: 'dadjokes', title: 'Fun APIs' },
    { id: 'contact', title: 'Contact' },
];

const Header: React.FC = () => {
    const [active, setActive] = useState<string>('');
    const [toggle, setToggle] = useState<boolean>(false);

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            const headerOffset = 120; // Adjust this value based on your header height
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });

            setActive(id);
            setToggle(false);
        }
    };

    return (
        <nav className={headerStyles.navbar}>
            <div className={headerStyles.navbarContainer}>
                <div className={headerStyles.logo}>
                    <img src={logo} alt="Logo"/>
                    <span>Andrew McKenzie | Developer</span>
                </div>
                <ul className={`${headerStyles.navLinks} ${toggle ? headerStyles.active : ''}`}>
                    {navLinks.map((link) => (
                        <li
                            key={link.id}
                            className={active === link.id ? headerStyles.active : ''}
                            onClick={() => scrollToSection(link.id)}
                        >
                            {link.title}
                        </li>
                    ))}
                </ul>
                <div className={headerStyles.menuIcon} onClick={() => setToggle(!toggle)}>
                    {toggle ? '✖' : '☰'}
                </div>
            </div>
        </nav>
    );
};

export default Header;