import React, { useEffect, useRef, useState } from 'react';
import style from '../styles/welcome.module.css';
import fader from '../styles/fader.module.css';

const Welcome: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section
            id="welcome"
            className={`${fader.container} ${isVisible ? fader.fadeIn : fader.fadeOut} ${style.container}`}
            ref={sectionRef}
        >
            <div>
                <h2 className={style.h2}>Hi!</h2>
                <p className={style.p}>I'm Andrew!</p>
            </div>
        </section>
    );
};

export default Welcome;