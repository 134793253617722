import React, { useEffect, useRef, useState } from 'react';
import style from '../styles/skills.module.css';
import fader from '../styles/fader.module.css';
import SkillImages from './SkillsImages';
import skillImage from '../assets/skills.png';
import section from '../styles/section.module.css';


const Skills: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [expandedItems, setExpandedItems] = useState<number[]>([]);

    const items = [
        {
            id: 1,
            title: 'Java',
            description: 'Three years experience coding in Java (object-oriented programming)',
            content:
                'Java has been my go-to language since I started programming. The range of programs completed includes a terminal-based\n' +
                'messaging system using RSA, AES, and SHA-256 for security measures, a space-themed maze game, a Missile Command\n' +
                'game, and many small OOP projects around user input and selection. Most are written using Java 17.',
        },
        {
            id: 2,
            title: 'Full Stack Web Development',
            description: 'One year of experience in full-stack web development with Laravel, React, MySQL, MongoDB, and more',
            content:
                'I started learning React in late 2023 and have spent my free time visualising designs and researching their implementation,\n' +
                'for example, tilt cards, motion, and various routing techniques. In the Advanced Web unit, I tied this all together with theory\n' +
                'and a front-end blog site in React communicating via RESTful APIs with a backend admin site developed in Laravel (PHP\n' +
                'framework) and a database in MySQL which I temporarily converted to MongoDB for learning purposes.',
        },
        {
            id: 3,
            title: 'Management Information Systems',
            description: 'Thorough understanding of Management Information Systems and their relation to business processes',
            content:
                'Management information systems was one of my most dominant units. I excelled at tasks such as critically analysing cloud\n' +
                'solutions for digital banking and completing the unit with 98/100 and 100% on the final proctored exam.',
        },
        {
            id: 4,
            title: 'C Programming',
            description: 'Low-level C programming, including MPI, CUDA C, and POSIX programming',
            content:
                'I have written various programs in C handling memory management and providing concurrency through Multi-threaded and\n' +
                'Multi-process programming with POSIX library functions and Open MPI for distributed computing, plus some CUDA C GPU\n' +
                'programming.',
        },
        {
            id: 5,
            title: 'Bash',
            description: 'Bash Scripting and Linux CLI',
            content:
                'I have experience creating basic Bash scripts for running programs, searching files, etc. I have spent plenty of time with\n' +
                'Fedora and some time with Ubuntu working through the CLI.',
        },
        {
            id: 6,
            title: 'Python and PyGame',
            description: 'Python programming for AI and game development',
            content:
                'Programming with both imperative and object-oriented styles, including implementation of AI algorithms such as Monte\n' +
                'Carlo Tree Search and Minimax algorithms for automated gameplay decision-making.',
        },
        {
            id: 7,
            title: 'Software Development Lifecycle',
            description: 'Agile Methodologies in the Software Development Lifecycle',
            content:
                'Having studied various software development techniques in Software Project Management. I have a thorough understanding\n' +
                'of Agile methods such as Scrum and Kanban, and I have used both Kanban in Trello and a watered-down version of Scrum in\n' +
                'Gitlab during some group projects.',
        },
        {
            id: 8,
            title: 'Git version control',
            description: 'Git, Github, and Gitlab',
            content:
                'I have been working with git for about 18 months now, and for the last 6 months, I have been using Github on all projects\n' +
                'going beyond the basic add, commit, and push cycle and using branching, issues, merging, rebasing, etc. I have also used\n' +
                'Gitlab for a major group project.',
        },
        {
            id: 9,
            title: 'APIs',
            description: 'RESTful API Development and public API integration',
            content:
                'I have experience developing RESTful APIs and testing through POSTMAN. I have also integrated public APIs into this website below.'
        },
        {
            id: 10,
            title: 'Database Languages',
            description: 'MySQL, MongoDB, and PHP',
            content:
                'I have experience with MySQL through both the CLI and MySQL Workbench for iOS plus basic MongoDB. I have also gone\n' +
                'through the process of converting a project from MySQL to MongoDB.',
        },
        {
            id: 11,
            title: 'Javascript and Typescript',
            description: 'Javascript and Typescript for web development plus some computational problems',
            content:
                'I have experience coding for computational problems in Javascript, plus some vanilla JS web development for dynamic images\n' +
                'and page interactions. Recently, I have been primarily using Typescript with React including with me personal website.',
        },
        {
            id: 12,
            title: 'Scala',
            description: 'Functional and Reactive Programming in Scala',
            content:
                'I completed an excellent university unit in Scala which covered functional and reactive programming.'
        },
        {
            id: 13,
            title: 'Docker',
            description: 'University and online course experience with Docker',
            content:
                'I have experience in Docker through both a university unit covering it and a certification of completion from a CodeWithMosh online course.',
        },
        {
            id: 14,
            title: 'AWS',
            description: 'Cloud Practitioner Certification in progress',
            content:
                'I chose AWS as my focus to work through cloud provider certifications and will sit the Cloud Practitioner exam shortly before\n' +
                'moving on to AI Practitioner and then Associate Developer. I’m aiming to have all three completed by the end of 2024.',
        },
        {
            id: 15,
            title: 'MVC Architecture',
            description: 'Worked with Laravel to build a blog site which uses an MVC architecture',
            content:
                'I have experience building a backend blog site with Laravel using an MVC architecture.',
        },
        {
            id: 16,
            title: 'Microservices',
            description: 'University experience with Microservice architecture',
            content:
                'Having covered Microservice architecture at University and my love of well-defined, loosely coupled OOP, I am in a strong\n' +
                'position to work on projects with a Microservice architecture.',
        },
        {
            id: 17,
            title: 'Diagramming',
            description: 'UML, Sequence, use-case diagrams, Gantt Charts, and work breakdown structures',
            content:
                'I have practiced UML, Sequence, and use-case diagrams, plus I have completed Gantt Charts and work breakdown\n' +
                'structures. Most importantly, I have written many README files with markdown.',
        },
    ];

    const handleItemClick = (id: number) => {
        if (expandedItems.includes(id)) {
            setExpandedItems(expandedItems.filter(itemId => itemId !== id));
        } else {
            setExpandedItems([...expandedItems, id]);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Fade in
                } else {
                    setIsVisible(false); // Fade out
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section
            id="skills"
            className={`${fader.container} ${isVisible ? fader.fadeIn : fader.fadeOut} ${section.container}`}
            ref={sectionRef}
        >
            <img
                src={skillImage}
                alt="Skills Icon"
                className={style.skillImage}
            />
            <h2>Skills</h2>
            <p>Throughout my degree, I have been exposed to a wide range of languages, tools, and frameworks. The languages I am most familiar with are Java and C. A stand-out Java project was during a class-wide mini-game server project where I led a team of 4 within the class of 40 to work together on a monorepo with Java and Scala. In this, we monitored the project through Jenkins and used Gitlab for version control, issues etc.
            </p>

            <p>Web Development involved building a static page with animations using HTML5, CSS and vanilla JS (before switching to jQuery), then Advanced web involved building and deploying a frontend blog using React and Typescript, as well as creating the API, database, and backend admin site with authentication using Laravel, MySQL and temporarily converting it to MongoDB.
            </p>

            <p>I've also covered a range of soft skills and business-specific skills such as building a Software development plan with a Gantt Chart, dependency relationships, work breakdown structure etc. Plus essays on the use of cloud computing in business, data management in social business, and case studies on failed software projects to name a few.</p>

            <p><strong>Experience</strong></p>
            <p>Expand the descriptions below for further details.</p>
            <div className={style.itemsContainer}>
                {items.map(item => (
                    <div key={item.id} className={style.item}>
                        <div
                            className={style.itemHeader}
                            onClick={() => handleItemClick(item.id)}
                            role="button"
                            tabIndex={0}
                            onKeyPress={() => handleItemClick(item.id)}
                        >
                            <span><strong>{item.title}{': '}</strong>{item.description}</span>
                            <span
                                className={`${style.arrow} ${
                                    expandedItems.includes(item.id)
                                        ? style.arrowExpanded
                                        : ''
                                }`}
                            ></span>
                        </div>
                        {expandedItems.includes(item.id) && (
                            <div className={style.itemContent}>
                                <p>{item.content}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <SkillImages/>
        </section>
    );
};

export default Skills;