import React, {useEffect, useRef, useState} from 'react';
import style from '../styles/about.module.css'; // Your updated styles
import fader from '../styles/fader.module.css';
import aboutImage from '../assets/about.png';
import section from '../styles/section.module.css';

const About: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Fade in
                } else {
                    setIsVisible(false); // Fade out
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section
            id="about"
            className={`${fader.container} ${isVisible ? fader.fadeIn : fader.fadeOut} ${section.container}`}
            ref={sectionRef}
        >
            <img
                src={aboutImage}
                alt="About Icon"
                className={style.aboutImage}
            />
            <h2>About Me</h2>
            <p><strong>My Past</strong></p>
            <p>Born in New Zealand and now an Australian citizen, I spent my teenage years racing BMX internationally which led me to complete a Certificate in Sport Management and Diploma in Exercise Prescription. After moving on from BMX racing, I went into logistics and worked as a warehouse/logistics coordinator for about 8 years. I had always wanted to go to university and challenge myself mentally and in 2022 I decided it was now or never. The core reasons behind choosing Computer Science were that it is a subject that resonates with my natural curiosity to both understand how things work and solve problems with logical reasoning and deduction.
            </p>

            <p><strong>My Present</strong></p>
            <p>Since starting the degree, I have struggled to narrow down which areas I enjoy most, but I know object-oriented programming, particularly with Java is the most natural for me which is why this is my go-to language and style. In saying that, I also love C programming and BASH scripting due to the control, reliability and speed that come with them. Plus, recently I have also been thoroughly enjoying the scalability and development speed of modern web frameworks.
            </p>
            <p><strong>My Future</strong></p>
            <p>With all that being said, I see a long-term future in using my attention to detail and critical thinking in the larger software development lifecycle and solutions architecture. This interest came about when I studied Management Information Systems and took a strong liking to connecting tech with real-world business requirements.
            </p>
            <p>In summary, there aren't many areas I don't have an interest in, I simply love Computer Science.</p>
        </section>
    );
};

export default About;